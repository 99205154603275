<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆自编号" prop="vehicleNo">
        <a-input v-model="form.vehicleNo" placeholder="请输入车辆自编号" />
      </a-form-model-item>
      <a-form-model-item label="年审日期" prop="annualReviewDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.annualReviewDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="到期日期" prop="deadlineDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.deadlineDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="年审费用(元)" prop="cost">
        <a-input v-model="form.cost" placeholder="请输入年审费用(元)" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <a-form-model-item label="年审凭证上传" prop="photo">
        <file-upload v-model="form.photo" :folder="'vehicleAccount/' + form.vehicleNo" type="image"></file-upload>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAnnualReview, addAnnualReview, updateAnnualReview } from '@/api/iot/annualReview'
export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        vehicleNo: null,
        annualReviewDate: null,
        deadlineDate: null,
        cost: null,
        remark: null,
        photo: null,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleNo: [{ required: true, message: '车辆自编号不能为空', trigger: 'blur' }],
        annualReviewDate: [{ required: true, message: '年审日期不能为空', trigger: 'change' }],
        deadlineDate: [{ required: true, message: '到期日期不能为空', trigger: 'change' }],
        cost: [{ required: true, message: '年审费用(元)不能为空', trigger: 'blur' }],
        photo: [{ required: true, message: '年审凭证上传不能为空', trigger: 'blur' }]
      },
      imagePhoto: null
    }
  },
  filters: {},
  created() {},
  computed: {
  },
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        vehicleNo: null,
        annualReviewDate: null,
        deadlineDate: null,
        cost: null,
        remark: null,
        photo: null,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd(curVehicleNo) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.form.vehicleNo = curVehicleNo
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAnnualReview(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAnnualReview(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addAnnualReview(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
