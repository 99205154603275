var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆自编号", prop: "vehicleNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆自编号" },
                model: {
                  value: _vm.form.vehicleNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleNo", $$v)
                  },
                  expression: "form.vehicleNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "年审日期", prop: "annualReviewDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.annualReviewDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "annualReviewDate", $$v)
                  },
                  expression: "form.annualReviewDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "到期日期", prop: "deadlineDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.deadlineDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deadlineDate", $$v)
                  },
                  expression: "form.deadlineDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "年审费用(元)", prop: "cost" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入年审费用(元)" },
                model: {
                  value: _vm.form.cost,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cost", $$v)
                  },
                  expression: "form.cost",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "年审凭证上传", prop: "photo" } },
            [
              _c("file-upload", {
                attrs: {
                  folder: "vehicleAccount/" + _vm.form.vehicleNo,
                  type: "image",
                },
                model: {
                  value: _vm.form.photo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "photo", $$v)
                  },
                  expression: "form.photo",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }