import request from '@/utils/request'

// 查询车辆年审列表
export function listAnnualReview (query) {
  return request({
    url: '/iot/annualReview/list',
    method: 'get',
    params: query
  })
}

// 查询车辆年审详细
export function getAnnualReview (id) {
  return request({
    url: '/iot/annualReview/' + id,
    method: 'get'
  })
}

// 新增车辆年审
export function addAnnualReview (data) {
  return request({
    url: '/iot/annualReview',
    method: 'post',
    data: data
  })
}

// 修改车辆年审
export function updateAnnualReview (data) {
  return request({
    url: '/iot/annualReview',
    method: 'put',
    data: data
  })
}

// 删除车辆年审
export function delAnnualReview (id) {
  return request({
    url: '/iot/annualReview/' + id,
    method: 'delete'
  })
}

// 导出车辆年审
export function exportAnnualReview (query) {
  return request({
    url: '/iot/annualReview/export',
    method: 'get',
    params: query
  })
}
